import React from 'react'
import SideTab from '../Components/Menaxhim/SideTab'
import Navbar from '../Shared/Navbar'
import Footer from '../Shared/Footer'
import './Styles/Menaxhimi.scss'

const Menaxhimi = () => {
  return (
    <div className='menaxhimi-page'>
      <Navbar/>
      <SideTab/>
      <Footer/>
    </div>
  )
}

export default Menaxhimi