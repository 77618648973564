import React from "react";
import { FaFacebookF, FaGithub, FaGoogle, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import jwtDecode from "jwt-decode"; // Import the jwt-decode library

const Footer = () => {
    const token = localStorage.getItem("token");

    let decodedToken;
    try {
      decodedToken = jwtDecode(token);
      console.log("Decoded Token:", decodedToken);
    } catch (error) {
      console.error("Error decoding token:", error);
    }

    function logout() {
        localStorage.removeItem('token');
        window.location.href = "/";
      }
  return (
    <div className="footer">
        
      {/* <footer class="bg-dark text-center text-lg-start">
      {token ? (
          <p>You are logged in.</p>
        ) : (
          <p>You are not logged in.</p>
        )}
      </footer> */}

<footer class="bg-dark text-white">
  {/* <div class="container p-4 pb-0">
    <section class="mb-4 text-center">
      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"><FaFacebookF/></a>

      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"> <FaTwitter/> </a>

      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"> <FaGoogle/> </a>

      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"> <FaInstagram/> </a>

      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"> <FaLinkedinIn/> </a>

      <a class="btn btn-outline-light btn-floating m-1" href="#!" role="button"> <FaGithub/> </a>
    </section>
  </div> */}

  <div class=" p-3 d-flex justify-content-between align-items-center  " style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
  {token ? (
          <p>You are logged in.</p>
        ) : (
          <p>You are not logged in.</p>
        )}

        <button className="btn btn-secondary" onClick={logout}>Log Out</button>
  </div>
</footer>
    </div>
  );
};

export default Footer;
