import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div className="navbar p-0">
        <nav className="navbar navbar-expand-lg navbar-dark bg-secondary w-100">
          <div className="container ">
            <Link className="navbar-brand" to="/Home">
              Kompania
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
              <ul className="navbar-nav ">
                <li className="nav-item">
                  <Link className="nav-link" aria-current="page" to="/bileta">
                    Biletat
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/menaxhimi">
                    Menaxhim
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
