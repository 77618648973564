import axios from "axios";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { useRef } from "react";

const Ditore = () => {
  const token = localStorage.getItem("token");

  const [startDate, setStartDate] = useState(new Date());
  const [ditore, setDitore] = useState([]);
  // const [endDate, setEndDate] = useState(new Date());

  const getDitore = async () => {
    try {
      const response = await axios.get(
        "https://amr-travel.al/php/bileta.php",
        {
          headers: {
            // Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setDitore(response.data);
      console.log(response.data);
    } catch (err) {
      console.log(err);
    }
    // axios.get("http://localhost:3001/bileta/getBileta"),{
    //   headers: {
    //     Authorization: `Bearer ${token}`
    //   }
    // }

    // .then((data)=>{
    //   console.log(data)
    //   setDitore(data.data)
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
  };

  useEffect(() => {
    getDitore();
  }, [startDate]);

  const filteredDitore = ditore.filter((item) => {
    const itemDate = new Date(item.date_booked); // Assuming the date property in the 'ditore' array is named 'date'
    return itemDate.toDateString() === startDate.toDateString();
  });

  const getTotalSum = () => {
    return filteredDitore.reduce((total, ticket) => total + Number(ticket.price), 0);
  };
  
  const getProfitSum = () => {
    return filteredDitore.reduce((total, ticket) => total + Number(ticket.profit), 0);
  };
  
  const getNetoSum = () => {
    return filteredDitore.reduce((total, ticket) => total + Number(ticket.neto), 0);
  };
  

  // table ref - with library
  const TableRef = useRef(null);

  // export table without library
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredDitore);
    const wb = { Sheets: { reservations: ws }, SheetNames: [`reservations`] };
    const ExcelBuffer = XLSX.write(wb, { bookType: `xlsx`, type: `array` });
    const data = new Blob([ExcelBuffer], { type: fileType });
    FileSaver.saveAs(data, `Reservation List` + fileExtension);
  };

  return (
    <>
      <div className="ditore">
        <div className="container-fluid ">
          <div className="row mb-3">
            <div className="col-4">
              <DatePicker
                className="form-control"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
              />
            </div>
            {/* <div className="col-4 d-flex justify-content-center">
              <DatePicker
                className="form-control"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
              />
            </div> */}
          </div>
          <div className="table-responsive" style={{width: "95%"}}>
            <table class="table">
            <thead>
              <tr>
                <th scope="col">Seria</th>
                <th scope="col">Emri</th>
                <th scope="col">Mbiemri</th>
                <th scope="col">Kompania</th>
                <th scope="col">Nisja</th>
                <th scope="col">Destinacioni</th>
                <th scope="col">Data Nisjes</th>
                <th scope="col">Data Kthimit</th>
                <th scope="col">Ora e nisjes</th>
                <th scope="col">Nr Tel</th>
                <th scope="col">Cmimi</th>
                <th scope="col">tsc</th>
                <th scope="col">Profit</th>
                <th scope="col">Neto</th>
                <th scope="col">Agjenti</th>
                {/* <th scope="col">Data e prerjes</th> */}
                {/* <th scope="col">Likujdimi</th> */}
              </tr>
            </thead>
            <tbody>
              {filteredDitore.map((el, index) => (
                <tr key={index}>
                  <td>{el.series}</td>
                  <td>{el.client_name}</td>
                  <td>{el.client_surname}</td>
                  <td>{el.company_name}</td>
                  <td> {el.departure_location} </td>
                  <td> {el.arrival_location} </td>
                  <td>
                    {" "}
                    {new Date(el.departure_date).toLocaleDateString(
                      "en-GB"
                    )}{" "}
                  </td>
                  <td>
                    {el.arrival_date
                      ? new Date(el.arrival_date).toLocaleDateString()
                      : ""}
                  </td>
                  <td> {el.departure_time} </td>
                  <td> {el.phone_no} </td>
                  <td> {el.price} </td>
                  <td> {el.tsc} </td>
                  <td> {el.profit} </td>
                  <td> {el.neto} </td>
                  <td> {el.agjenti} </td>
                  {/* Add other table cells here based on your data */}
                </tr>
              ))}
            </tbody>
          </table>
          </div>
          
          <div className="row col-6 mt-5">
            <div className="col-md-3">
              <p> Total: {getTotalSum()} </p>
            </div>
            <div className="col-md-3">
              <p> Profit: {getProfitSum()} </p>
            </div>
            <div className="col-md-3">
              <p> Neto: {getNetoSum()} </p>
            </div>
          </div>

          <div>
            <button className="btn btn-success mb-5" onClick={exportToExcel}>Export</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default Ditore;
