import React, {useState, useEffect} from 'react'
import axios from "axios";
import {TiTick} from "react-icons/ti";

const Users = () => {
  const token = localStorage.getItem("token");

  const [isShowing, setIsShowing] = useState(false);

  const [allUsers, setAllUsers] = useState([]);

  const [values, setValues] = useState({
    email: "",
    username: "",
    password: "",
    role: "user"
  });

  const [errors, setErrors] = useState({});

  const handleInput =  (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // setErrors(validation(values));
    // console.log(values)

    if(!errors.email && !errors.username && !errors.password && !errors.confirmPassword){
      try{
        const response = await axios.post("https://amr-travel.al/php/user.php", values, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        setValues({
                email: "",
                username: "",
                password: "",
                role: "user"
      
            })
            console.log(response)
        //     // navigate("/");
    //     setValues({
    //       email: "",
    //       username: "",
    //       password: "",
    //       role: "user"

    //   })})
      } catch (err){
        console.log(err)
      }

    //   axios.post("http://localhost:3001/user/addUser", values)
    //   .then((res)=>{
    //     console.log(res)
    //     // navigate("/");
    //     setValues({
    //       email: "",
    //       username: "",
    //       password: "",
    //       role: "user"

    //   })})
    //   .catch((err)=>{
    //     console.log(err)
    //   }
    //   )
    // }
  }};

  const getUsers = async () => {
    try{
      const response = await axios.get("https://amr-travel.al/php/user.php", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      setAllUsers(response.data)
      console.log(response.data)
    }
    catch(err){
      console.log(err)
    }
  }

  // const getUsers = () => {
  //   axios.get("http://localhost:3001/user/showUsers")
  //   .then((res)=>{
  //     setAllUsers(res.data)
  //     console.log(res.data)
  //   })
  //   .catch((err)=>{
  //     console.log(err)
  //   })
  // }

  useEffect(()=>{
    getUsers()
  },[])

  return (
    <div>
      <div className="container">
        <div className="d-flex flex-wrap gap-2">
          <div className="button-div">
            <button
              className="btn btn-primary rounded-circle pt-2 pb-2 ps-3 pe-3"
              onClick={(e) => {
                setIsShowing((current) => !current);
              }}
            >
              +
            </button>
            {isShowing && (
              <form
                className="form border p-3 mt-2 ms-3 rounded"
                onSubmit={handleSubmit}
              >
                 <div className="d-flex gap-3">
                 <input
                    className="mb-2 form-control"
                    placeholder="username"
                    type='text'
                    name='username'
                    onChange={handleInput}
                    // value={companyName}
                    // onChange={(e) => {
                    //   setCompanyName(e.target.value);
                    // }}
                  ></input>
                  <input
                    className="mb-2 form-control"
                    placeholder="email"
                    type='email'
                    name='email'
                    onChange={handleInput}
                    // value={addCity}
                    // onChange={(e) => {
                    //   setAddCity(e.target.value);
                    // }}
                  ></input>
                  <input type="text" className='mb-2 form-control' placeholder='password' name='password' onChange={handleInput}/>
                  <select name="role" id="role" className='mb-2 form-select' placeholder='role' onChange={handleInput}>
                    <option value="user" selected>user</option>
                    <option value="admin">admin</option>
                  </select>
                 </div>
                <button type="submit" className="btn btn-success mt-3">
                  Shto
                </button>
              </form>
            )}
          </div>

          
          <div className="container col-12 d-flex flex-wrap gap-1">
            {allUsers.length ? (
              <div className="d-flex flex-wrap gap-1">
                {allUsers.map((el) => {
                  return (
                    <div class="card" style={{ width: "18rem" }}>
                      <div class="card-body">
                        <h5 class="card-title"> {el.username} </h5>
                        <h6 class="card-subtitle mb-4 text-muted">{el.role}</h6>
                        <p> Email: {el.email}</p>
                        <p>Password: {el.password}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Users