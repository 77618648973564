import React, { useEffect, useState } from "react";
import { TiTick } from "react-icons/ti";
import axios from "axios";

const Kompanite = () => {
  const token = localStorage.getItem("token");

  
  const [isShowing, setIsShowing] = useState(false);
  const [addCity, setAddCity] = useState("");
  const [allCities, setAllCities] = useState([]);
  const [companyName, setCompanyName] = useState("");
  const [companyTsc, setCompanyTsc] = useState("");
  const [chosenTsc, setChosenTsc] = useState("");

  const [allCompanies, setAllCompanies] = useState([]);

  const addToSelect = () => {
    if (addCity.trim() !== "") {
      setAllCities((prevOptions) => [...prevOptions, addCity]);
      setAddCity("");
    }
  };

  const addCompany = async (e) => {
    e.preventDefault();
    const companyData = {
      companyName: companyName,
      companyTsc: companyTsc,
      companyCities: JSON.stringify(allCities),
      chosenTsc: chosenTsc
    };

    if (companyName == "" || companyTsc == "" || allCities.length === 0 || chosenTsc === "")  {
      alert("fill info");
    } else {
      try{
        const response = await axios.post("https://amr-travel.al/php/company.php", companyData,{
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        console.log("response", response);
        setCompanyName("");
          setCompanyTsc("");
          setAllCities([]);
          setCompanyTsc("");
      } catch (err) {
        console.log("err", err);
      }
    }
  };

  useEffect(() => {
    console.log("QYTETET", JSON.stringify(allCities));
  }, [allCities]);

  const showCompanies = async () => {
    try{
      const response = await  axios
      .get("https://amr-travel.al/php/company.php", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      console.log("response", response);
      setAllCompanies(response.data);
    } catch (err) {
      console.log("err", err);
    }
    // axios
    //   .get("http://localhost:3001/addCompany/showCompanies")
    //   .then((data) => {
    //     setAllCompanies(data.data);
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  };

  useEffect(() => {
    showCompanies();
  },[]);

  return (
    <div>
      <div className="container">
        <div className="d-flex flex-wrap gap-2">
          <div className="button-div">
            <button
              className="btn btn-primary rounded-circle pt-2 pb-2 ps-3 pe-3"
              onClick={(e) => {
                setIsShowing((current) => !current);
              }}
            >
              +
            </button>
            {isShowing && (
              <form
                className="form border p-3 mt-2 ms-3 rounded"
                onSubmit={addCompany}
              >
                <div className="d-flex gap-2">
                  <input
                    className="form-control"
                    placeholder="company name"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  ></input>
                  <input
                    className="form-control"
                    placeholder="city"
                    value={addCity}
                    onChange={(e) => {
                      setAddCity(e.target.value);
                    }}
                  ></input>
                  <a
                    className="btn d-flex align-items-center"
                    onClick={addToSelect}
                  >
                    <TiTick />
                  </a>
                </div>
                <div className="d-flex gap-2 mt-2">
                 
                  <select
                    className="form-select"
                    placeholder="qytetet"
                    // value={allCities}
                    // onChange={(e) => {
                    //   const selectedCity = e.target.value;
                    //   setAllCities((prevCities) => [...prevCities, selectedCity]);
                    // }}
                  > {allCities.map((el, index) => (
                    <option key={index}>{el}</option>
                  ))}
                  </select>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="tsc"
                    value={companyTsc}
                    onChange={(e) => {
                      setCompanyTsc(e.target.value);
                    }}
                  />
                  <select name="chosenTsc" id="chosenTsc" className="form-select" value={chosenTsc} onChange={(e) => {setChosenTsc(e.target.value)}}>
                    <option value="percentage">Perqindje</option>
                    <option value="fixed">Fikse</option>
                  </select>
                </div>
                <button type="submit" className="btn btn-success mt-3">
                  Shto
                </button>
              </form>
            )}
          </div>

          <div className="container col-12 d-flex flex-wrap gap-1">
            {allCompanies.length ? (
              <div className="d-flex flex-wrap gap-1">
                {allCompanies.map((el) => {
                  return (
                    <div class="card" style={{ width: "18rem" }}>
                      <div class="card-body">
                        <h5 class="card-title"> {el.company_name} </h5>
                        <h6 class="card-subtitle mb-2 text-muted">{el.tsc}</h6>
                        <select name="" id="" className="form-select">
                          {JSON.parse(el.cities).map((city, index) => (
                            <option key={index} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kompanite;
