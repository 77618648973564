import React, { useState, useEffect } from "react";
import "../Style/Booking.scss";
import axios from "axios";
import { render } from "@testing-library/react";
//toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from "react-datepicker";
import { FaCheck } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";

const Booking = () => {
  const token = localStorage.getItem("token");

  const [clientName, setClientName] = useState("");
  const [clientSurname, setClientSurname] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [selectedCompany, setSelectedCompany] = useState("");
  const [departureLocation, setDepartureLocation] = useState("");
  const [arrivalLocation, setArrivalLocation] = useState("");
  const [departureDate, setDepartureDate] = useState(new Date());
  const [arrivalDate, setArrivalDate] = useState(null);
  const [price, setPrice] = useState("");
  const [tsc, setTsc] = useState(0);
  const [profit, setProfit] = useState(0);
  const [likujdimi, setLikujdimi] = useState(0);
  const [dateBooked, setDateBooked] = useState(new Date());
  const [seria, setSeria] = useState("");
  const [departureTime, setDepartureTime] = useState("");
  const [agjenti, setAgjenti] = useState("");
  const [likujduar, setLikujduar] = useState("Jo");

  const [user, setUser] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");

  const [chosenTsc, setChosenTsc] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [companyCities, setCompanyCities] = useState([]);

  const showCompanies = async () => {
    try {
      const response = await axios.get(
        "https://amr-travel.al/php/company.php",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCompanyList(response.data);
      setSelectedCompany(response.data[0].company_name);
      setCompanyCities(JSON.parse(response.data[0].cities));
      setTsc(response.data[0].tsc);
      console.log("companyList",companyList);
    } catch (error) {
      console.log("err", error);
    }
  };

  useEffect(() => {
    // showUsers();
    console.log("user", user);
    console.log("selectedUser", selectedUser);
  }, [user, selectedUser]);
  // const showCompanies = () => {
  //   axios
  //     .get("http://localhost:3001/addEntry/getCompanies")
  //     .then((data) => {
  //       // console.log("data", data.data);
  //       setCompanyList(data.data);
  //       setSelectedCompany(data.data[0].company_name);
  //       setCompanyCities(JSON.parse(data.data[0].cities));
  //       setTsc(data.data[0].tsc);
  //       // console.log("chosenTsc", chosenTsc);
  //     })
  //     .catch((err) => {
  //       console.log("err", err);
  //     });
  // };

  // const showUsers = () => {
  //   axios
  //   .get("http://localhost:3001/user/showUsers")
  //   .then((data) => {
  //     setUser(data.data);
  //     console.log("user", user);
  //   })
  //   .catch((err) => {
  //     console.log("errori", err);
  //   });;
  // }

  const showUsers = async () => {
    try {
      const response = await axios.get("https://amr-travel.al/php/user.php", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUser(response.data);
      setSelectedUser(response.data[0].username);
      // console.log("user", response.data);
      console.log("user", user)
      console.log("selectedUser", selectedUser)
    } catch (error) {
      console.log("err", error);
    }
  }

  useEffect(() => {
    showCompanies();
    showUsers();
  }, []);

  // const [likujdimi,setLikujdimi] = useState(0);

  const handleLikujdimi = () => {
    if (chosenTsc === "perqindje") {
      const likujdimi = price - (price * tsc) / 100;
      setLikujdimi(likujdimi);
    } else {
      const likujdimi = price - tsc;
      setLikujdimi(likujdimi);
    }
  };

  const handleProfit = () => {
    if (chosenTsc === "perqindje") {
      const fitimi = (price * tsc) / 100;
      setProfit(fitimi);
      // console.log(profit);
    } else {
      const fitimi = tsc;
      setProfit(fitimi);
      // console.log(profit);
    }
  };

  useEffect(() => {
    handleLikujdimi();
    handleProfit();
  }, [tsc, price, likujdimi, profit]);

  const addBooking = async (e) => {
    e.preventDefault();

    const formattedDateBooked = dateBooked.toISOString().split("T")[0];
    const formattedDepartureDate = departureDate.toISOString().split("T")[0];
    const formattedArrivalDate = arrivalDate ? arrivalDate.toISOString().split("T")[0] : null;

    const bookingData = {
      clientName: clientName,
      clientSurname: clientSurname,
      phoneNo: phoneNo,
      companyName: selectedCompany,
      departureLocation: departureLocation,
      arrivalLocation: arrivalLocation,
      departureDate: formattedDepartureDate,
      arrivalDate: formattedArrivalDate,
      price: price,
      tsc: tsc,
      profit: profit,
      likujdimi: likujdimi,
      dateBooked: formattedDateBooked,
      seria: seria,
      departureTime: departureTime,
      agjenti: selectedUser,
      likujduar: likujduar,
    };

    if (
      !clientName  ||
      !clientSurname  ||
      !phoneNo  ||
      !selectedCompany  ||
      !departureLocation  ||
      !arrivalLocation  ||
      !departureDate  ||
      !price  ||
      !tsc  ||
      !profit  ||
      !likujdimi  ||
      !dateBooked  ||
      !seria  ||
      !departureTime  ||
      !selectedUser 
    ) {
      // console.log("agjenti", agjenti);
      // alert("fill info");
      console.log("booking data", bookingData)
      toast.error("Please fill out all the information!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      try {
        const response = await axios.post(
          "https://amr-travel.al/php/entries.php",
          bookingData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response);
        setClientName("");
        setClientSurname("");
        setPhoneNo("");
        setSelectedCompany("");
        setDepartureLocation("");
        setArrivalLocation("");
        setDepartureDate("");
        setArrivalDate("");
        setPrice("");
        setTsc("");
        setProfit("");
        setLikujdimi("");
        setSeria("");
        setDateBooked(new Date());
        setDepartureTime("");
        setAgjenti("");
        setLikujduar("");
        // window.location.reload();
        toast.success("Bileta u regjistrua!", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } catch (error) {
        console.log("err", error);
      }
    }
  };
  return (
    <>
      <div id="booking" class="section booking">
        <div class="section-center pt-5 pb-4">
          <div class="container">
            <div class="row">
              <div class="booking-form">
                <div class="form-header">
                  <h1>Shto rezervimin e ri</h1>
                </div>
                <form onSubmit={addBooking}>
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <DatePicker
                          className="form-control w-100"
                          placeholderText="MM/DD/YYYY"
                          selected={dateBooked}
                          onChange={(date) => {
                            setDateBooked(date);
                          }}
                        />
                        {/* <input type="date" value={dateBooked} onChange={(e)=>setDateBooked(e.target.value)} className="form-control" /> */}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <div className="form-group">
                          <input
                            type="text"
                            required
                            placeholder="seria"
                            value={seria}
                            onChange={(e) => setSeria(e.target.value)}
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          required
                          placeholder="Ora Nisjes"
                          value={departureTime}
                          onChange={(e) => setDepartureTime(e.target.value)}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3">

                        <select name="selectedUser" id="selectedUser" className="form-select" 
                        value={selectedUser} // Make sure to bind the value to the selectedUser state
                        onChange={(e) => setSelectedUser(e.target.value)} // Update the selectedUser state when the dropdown changes
                        >
                          {user.map((el)=>{
                            return(
                              <option value={el.username} key={el.id}> {el.username} </option>
                            )
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group mb-3">
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Emer"
                          value={clientName}
                          onChange={(e) => {
                            setClientName(e.target.value);
                            console.log("emri", clientName);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mb-3">
                        <input
                          class="form-control"
                          type="text"
                          placeholder="Mbiemer"
                          value={clientSurname}
                          onChange={(e) => {
                            setClientSurname(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nr cel"
                        value={phoneNo}
                        onChange={(e) => {
                          setPhoneNo(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-md-3">
                      <div class="form-group">
                        <select
                          className="form-select position-relative"
                          value={selectedCompany}
                          placeholder="Kompania"
                          onChange={(e) => {
                            const selectedCompanyName = e.target.value;
                            setSelectedCompany(selectedCompanyName);
                            const selectedCompanyData = companyList.find(
                              (company) =>
                                company.company_name === selectedCompanyName
                            );
                            setCompanyCities(
                              selectedCompanyData
                                ? JSON.parse(selectedCompanyData.cities)
                                : []
                            );
                            setTsc(selectedCompanyData.tsc);
                            setChosenTsc(selectedCompanyData.chosenTsc);
                          }}
                          required
                        >
                          {companyList.map((company) => {
                            return (
                              <option
                                value={company.company_name}
                                key={company.id}
                              >
                                {company.company_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-1">
                        <select
                          className="form-select position-relative"
                          value={departureLocation}
                          onChange={(e) => {
                            setDepartureLocation(e.target.value);
                          }}
                          required
                        >
                          <option value="" selected hidden>
                            Nisja
                          </option>
                          {companyCities.length > 0 &&
                            companyCities.map((city) => (
                              <option value={city} key={city}>
                                {city}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-1">
                        <select
                          className="form-select position-relative"
                          value={arrivalLocation}
                          onChange={(e) => {
                            setArrivalLocation(e.target.value);
                          }}
                          required
                        >
                          <option value="" selected hidden>
                            Nisja
                          </option>
                          {companyCities.length > 0 &&
                            companyCities.map((city) => (
                              <option value={city} key={city}>
                                {city}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <span class="form-label">Departure Date</span>
                        <DatePicker
                          className="form-control"
                          selected={departureDate}
                          placeholderText="MM/DD/YYYY"
                          showIcon
                          selectsStart
                          startDate={departureDate}
                          endDate={arrivalDate}
                          valueDefault={null}
                          onChange={(date) => {
                            setDepartureDate(date);
                          }}
                        />
                        {/* <input
                          class="form-control"
                          type="date"
                          value={departureDate}
                          onChange={(e) => {
                            setDepartureDate(e.target.value);
                          }}
                          required
                        /> */}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                        <span class="form-label">Return Date</span>
                        <DatePicker
                          className="form-control"
                          selected={arrivalDate}
                          onChange={(date) => {
                            setArrivalDate(date);
                          }}
                          placeholderText="MM/DD/YYYY"
                          showIcon
                          startDate={departureDate}
                          selectsEnd
                          endDate={arrivalDate}
                          minDate={departureDate}
                        />
                        {/* <input
                          class="form-control"
                          type="date"
                          value={arrivalDate}
                          onChange={(e) => {
                            setArrivalDate(e.target.value);
                          }}
                          required
                        /> */}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group mb-3">
                        <label class="form-label">Cmimi</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="Cmimi"
                          value={price}
                          onChange={(e) => {
                            setPrice(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mb-3">
                        <label class="form-label">TSC</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="tsc"
                          onChange={(e) => {
                            setTsc(e.target.value);
                          }}
                          value={tsc}
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mb-3">
                        <label class="form-label">Profit perqindje</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="profit"
                          value={profit}
                          readOnly
                        />
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group mb-3">
                        <label class="form-label">Likujdimi</label>
                        <input
                          class="form-control"
                          type="number"
                          placeholder="likujdimi"
                          value={likujdimi}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div class="form-btn gap-2 mt-3 d-flex justify-content-end">
                      <p
                        onClick={(e) => setLikujduar("Po")}
                        className={`btn w-25 h-100 d-flex gap-2 justify-content-center align-items-center btn-success`}
                      >
                        Likujduar {likujduar === "Po" ? <FaCheck /> : ""}
                      </p>
                      <button class="btn btn-warning w-25 text-white">
                        Shto Rezervimin
                      </button>
                      <ToastContainer />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Booking;